<template>
  <div class="page">
      <div class="top-back">
        <img src="../../static/images/workFission/award-top.png" alt="">
      </div>
      <div class="top">
        <div class="content">
          <div class="title">已获得奖励</div>
          <div class="half-pearl">
            <div class="left"></div>
            <div class="right"></div>
          </div>
          <div class="lie" v-for="task,key in dataList.task" v-if="task.status">【{{key+1}}级：{{task.rewardName}}】</div>
        </div>
      </div>

    <div class="code-content">
      <div class="title">领取奖励</div>

      <div v-if="!this.received">
        <div class="attention">注意：兑换奖励后，无法再获得下一阶段奖励。</div>
        <div class="code-content-container">
          <div class="title">
            Step 1 长按保存兑换码
          </div>
          <div class="img" v-if="fissionCodeUrl">
            <img :src="[fissionCodeUrl]">
          </div>
          <div class="title">
            Step 2 长按添加微信兑换奖励
          </div>
          <div class="img" v-if="qrcodeUrl">
            <img :src="[qrcodeUrl]" alt="">
          </div>
        </div>
      </div>
      <div v-else>
        <div class="code-content-container">
          <p>已领取，已核销</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {taskDataApi, openUserInfoApi, posterApi, exchangeCodeApi} from "../../api/workFission";
export default {
  name: "award",
  data(){
    return{
      dataList:{},
      fissionId:this.$route.query.id,
      unionId:this.$route.query.unionId,
      qrcodeUrl:'',
      fissionCodeUrl:'',
      received:1,
      //  微信用户数据
      wxUserData: {
      }
    }
  },
  created() {
    this.getOpenUserInfo()
  },
  methods: {
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        id: that.fissionId
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = '/auth/workFission?id=' + that.fissionId + '&target=' + encodeURIComponent(that.url);
          that.$redirectAuth(redirectUrl);
        }
        this.wxUserData = res.data
        this.getDataList()
      });
    },
    async getDataList() {
      //获取任务信息
      await taskDataApi({
        unionId: this.unionId,
        fissionId: this.fissionId
      }).then(res => {
        this.dataList = res.data;
        // 检测是否获得且未领取
        var task=this.dataList.task;
        var receivedP=0;
        for(var i=0;i<task.length;i++){
          if(task[i].receive_status){
            receivedP=1;
          }
          if(task[i].status==1&&task[i].receive_status==0){
            if(!receivedP){
              this.received=0;
              this.getPosterData()
              break;
            }
          }
        }
      })
    },
    // 获取码
    getPosterData() {
      // 获取兑换码
      let exchangeCodeparams = {
        fid: this.fissionId,
        uid: this.wxUserData.unionid,
      }
      exchangeCodeApi(exchangeCodeparams).then((res) => {
        console.log(res.data)
        return this.fissionCodeUrl = 'data:image/png;base64,'+res.data;
      })
      // 获取企业微信码
      let params = {
        fissionId: this.fissionId,
        unionId: this.wxUserData.unionid,
        nickname: this.wxUserData.nickname,
        avatar: this.wxUserData.headimgurl
      }
      posterApi(params).then((res) => {
        return this.qrcodeUrl = res.data.qrcodeUrl
      })
    }

  }
}
</script>
<style lang="less" scoped>
.page{
  display: flex;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  background: #F55C4B;
  font-weight: 600;
  .top-back{
    margin: 0 25px;
    img{
      width: 100%;
      height: auto;
    }
  }
  .top{
    display: flex;
    flex-direction: column;
    .content{
      display: flex;
      flex-direction: column;
      background: #FFE2AA;
      margin: 0 12.5px;
      border-radius: 5px;
      padding-bottom: 24px;
      .title{
        height: 55px;
        margin: 0 60px;
        line-height: 55px;
        font-size: 18px;
        text-align: CENTER;
        color: #cb5530;
        border-bottom: 1px dotted #D8A26F;
      }
      .half-pearl{
        height: 24px;
        display: flex;
        justify-content: space-between;
        .left{
          width: 24px;
          height: 24px;
          border-radius: 0 12px 12px 0;
          margin-left: -12px;
          background: #F55C4B;
        }
        .right{
          width: 24px;
          height: 24px;
          margin-right: -12px;
          border-radius: 12px 0 0 12px;
          background: #F55C4B;
        }
      }
      .lie{
        font-size: 14px;
        font-weight: 400;
        text-align: CENTER;
        color: #963e3e;
        line-height: 22px;
      }
    }
  }
  .code-content {
    display: flex;
    flex-direction: column;
    margin: 23px 13px;
    padding: 11px;
    border-radius: 16px;
    background: #EF453A;
    .title{
      font-size: 18px;
      color: #FFE5A3;
      text-align: center;
    }
    .attention{
      font-size: 14px;
      color: #FFECEA;
      margin-top: 11px;
      font-weight: 400;
    }
    .code-content-container{
      background: #FFF5EF;
      border-radius: 16px;
      padding: 28px;
      display: flex;
      flex-direction: column;
      margin-top: 17px;
      & >p{
        text-align: center;
        margin: 150px 0;
      }
      .title{
        color: #9F5951;
        font-size: 14px;
        margin-bottom: 7.5px;
        text-align: left;
      }
      .img{
        width: 130px;
        padding: 10px;
        background: white;
        border-radius: 16px;
        margin: 5px 0 17px 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>