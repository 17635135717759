<template>
  <div class="page">

    <div class="top">
      <div></div>
      <div v-if="dataList.activeIntroduce">{{dataList.activeIntroduce}}</div>
    </div>

    <div class="copy">
      <div><div>Step1 复制推荐文案</div><div @click="copyText(dataList.fowardText)">一键复制</div></div>
      <div>{{ dataList.fowardText }}</div>
    </div>

    <div class="content">
      <p>Step2 保存海报&分享</p>
      <div>
        长按保存专属海报分享给好友或朋友圈
        <br>
        好友扫码添加微信后即助力成功
      </div>

      <div class="persion-bill" v-if="posterType== 0">
        <!--        个人海报-->
        <img :src="posterImage" v-if="posterImage" >
      </div>

      <!--        个人名片-->
      <div id="code-template-simple" ref="template_simple" v-if="posterType== 1">
        <div class="info">
          <div class="left">
            <img class="logo" :src="dataList.cardCorpLogo">
          </div>
          <div class="right">
            <div class="title">{{ dataList.cardCorpImageName }}</div>
            <div class="name">{{ dataList.cardCorpName }}</div>
          </div>
        </div>
        <div class="code">
          <img class="qrcode" :src="dataList.qrcodeUrl">
          <img class="logo" :src="dataList.cardCorpLogo">
        </div>
        <div class="tip">扫一扫上面的二维码图案</div>
        <div class="tip">加我企业微信</div>
      </div>

    </div>

    <div class="bottom">
      <div  @click="$router.push('/workFission/speed?id='+fissionId+'&unionId='+wxUserData.unionid)">查看助力进度</div>
    </div>

    <input type="text" onfocus="false" readonly="readonly" ref="copyInput" style="position: fixed; opacity: 0">


    <canvas ref="canvas" width="720" height="1280">
      您的浏览器不支持canvas
    </canvas>
  </div>
</template>

<script>
import {posterApi, openUserInfoApi} from "@/api/workFission";

export default {
  name: "index",
  data() {
    return {
      fissionId: this.$route.query.id,
      //  页面类型
      posterType: 0,
      //海报图片
      posterImage: require('@/static/images/workFission/index-canvas-loading.png'),
      dataList: {
        fowardText:'正在获取推文....'
      },
      //  微信用户数据
      wxUserData: {
      },

    }
  },
  created() {
    this.getOpenUserInfo();
  },
  methods: {
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        id: that.fissionId
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = '/auth/workFission?id=' + that.fissionId + '&target=' + encodeURIComponent(that.url);
          that.$redirectAuth(redirectUrl);
        }
        this.wxUserData = res.data;
        this.getPosterData();
      });
    },
    //获取页面海报信息
    getPosterData() {
      let time1 = parseInt(new Date().getTime() / 1000) + '';
      console.log('海报请求开始',time1)
      let params = {
        fissionId: this.fissionId,
        unionId: this.wxUserData.unionid,
        nickname: this.wxUserData.nickname,
        avatar: this.wxUserData.headimgurl
      }
      posterApi(params).then((res) => {
        this.posterType = res.data.posterType
        this.dataList = res.data
        this.createPoster()
      })
      let time2 = parseInt(new Date().getTime() / 1000) + '';
      console.log('海报请求结束',time2)
    },
    async createPoster() {
      let time1 = parseInt(new Date().getTime() / 1000) + '';
      console.log('canvas开始',time1)

      const ctx = this.$refs.canvas.getContext('2d')

      const w = 720
      const h = 1280

      const bg = await this.getImg(this.dataList.coverPic)
      const qr = await this.getImg(this.dataList.qrcodeUrl)

      ctx.drawImage(bg, 0, 0, w, h)
      ctx.drawImage(qr, 550, 1100, 150, 150)

      if (this.wxUserData.nickname) {
        ctx.font = '30px Verdana'
        ctx.fillStyle = this.dataList.nicknameColor ?? '#fff'
        ctx.fillText(this.wxUserData.nickname, 115, 74)
      }

      if (this.wxUserData.headimgurl) {
        const avatar = await this.getImg(this.wxUserData.headimgurl)
        ctx.drawImage(avatar, 30, 30, 70, 70)
      }

      this.posterImage = this.$refs.canvas.toDataURL('image/png')

      let time2 = parseInt(new Date().getTime() / 1000) + '';

      console.log('canvas结束',time2)
    },
    copyText(text) {
      const inputElement = this.$refs.copyInput;
      inputElement.value = text;
      inputElement.select();
      document.execCommand('Copy');
      this.$message.success('复制成功');
      inputElement.value='';
    },
    getImg(url) {
      url += '?rand=' + Math.random()

      return new Promise(resolve => {
        const img = new Image()

        img.src = url
        img.crossOrigin = "Anonymous";

        img.onload = () => {
          resolve(img)
        }

        img.onerror = err => {
          console.log('图片加载失败');
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 720px;
  height: 1280px;
  z-index: 10000;
  display: none;
}

.page {
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(180deg,#f05248, #fd6e4f 100%);
  display: flex;
  flex-direction: column;
  font-weight: 400;
  .top{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    div:nth-child(1){
      width: 100%;
      height: 160px;
      background: url("../../static/images/workFission/index-top.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    div:nth-child(2) {
      margin: 0 20px 25px 20px;
      font-size: 14px;
      color: white;
    }
  }
  .copy{
    background: white;
    padding: 15px 19px;
    margin: 0 12px 20px 12px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    div:nth-child(1){
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      color: #111343;
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 600;
      div:nth-child(1){
        margin-top: 5px;
      }
        div:nth-child(2){
        display: block;
        width: 80px;
        height: 36px;
        font-size: 14px;
        line-height: 36px;
        background: linear-gradient(180deg,#ffea7b, #f2d32f 100%);
        border-radius: 30px;
        color: #d88345;
        text-align: center;
        z-index: 3;
      }
    }
    div:nth-child(2) {
      font-size: 14px;
      color: #999;
    }
  }

  .content{
    background: white;
    padding: 15px 19px;
    margin: 0 12px 20px 12px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    color: #333333;
    margin-bottom: calc(~"46px + 10%");
    p:nth-child(1){
      font-size: 16px;
      margin-bottom: 7px;
      font-weight: 600;
    }
    div:nth-child(2){
      font-size: 14px;
    }
    .persion-bill{
      margin-top: 16px;
      img{
        width: 100%;
        height: auto;
      }
    }

    //个人名片
    #code-template-simple {
      border: 10px solid navajowhite;
      border-radius: 10px;
      padding: 20px;
      margin-top: 20px;
      .info {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .left {
          margin-right: 10px;

          .logo {
            width: 34px;
            height: 34px;
          }
        }

        .right {
          .title {
            font-size: 14px;
            color: #000000;
          }

          .name {
            font-size: 12px;
          }
        }
      }

      .code {
        width: 84%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0 auto 20px;

        .qrcode {
          width: 100%;
          height: 100%;
        }

        .logo {
          border: 1px solid white;
          position: absolute;
          width: 24%;
          height: 24%;
        }
      }

      .tip {
        text-align: center;
      }
    }





  }

  .bottom{
    width: 100%;
    background: #FEDFD9;
    position: fixed;
    bottom: 0;
    div{
      width: 92%;
      height: 46px;
      line-height: 46px;
      background: linear-gradient(180deg,#e64e44, #ff9c7d 100%);
      border-radius: 48px;
      font-size: 16px;
      color: white;
      text-align: center;
      margin: 4%;
      font-weight: 600;
    }
  }

}


</style>
