<template>
  <div class="page">
    <div class="top">
      <div class="top-content">

        <div class="continue" v-if="!isEnd">
          <p>已有<font>{{invite_count}}</font>人完成助力</p>
          <p>还差<font>{{differ_count}}</font>人完成下一阶段的任务</p>
          <p><icon></icon></p>
        </div>

        <div class="end" v-if="isEnd">
          <p>
            <icon></icon><span>任务已结束</span>
          </p>
        </div>

      </div>
      <div class="top-icon"></div>
    </div>


    <div class="djs" v-if="!isEnd">
      还剩
      <span class="time">{{countDown.day}}</span>天
      <span class="time">{{countDown.hour}}</span>时
      <span class="time">{{countDown.min}}</span>分
      <span class="time">{{countDown.sec}}</span>秒后结束
    </div>


    <div class="content">
      <p>当前助力人数：<font>{{invite_count}}</font>人</p>
      <div class="content-lie"  :class="[task.status?'active':'']" v-for="task in task" >
        <img src="../../static/images/workFission/speed-gift-back.png">
        <div>
          <p>{{task.rewardName}}<font v-if="task.status">（已获得）</font></p>
          <p><font>{{task.count}}</font>个好友助力，可获得【{{task.rewardName}}】</p>
        </div>
      </div>
    </div>
    <div class="friendlist">
      <div class="title">我的助力好友</div>
      <div class="fl-content" v-for="fl in friendList">
        <img :src="[fl.avatar]" alt="">
        <div>
          <p>{{fl.nickname}}</p>
          <p>{{fl.createdAt}}</p>
        </div>
      </div>
    </div>
    <div class="bottom">

      <div  @click="getGift">领取奖励</div>
    </div>
  </div>
</template>

<script>
import {taskDataApi, inviteFriendsApi, receiveApi, openUserInfoApi} from "../../api/workFission";

export default {
  name: "speed",
  data() {
    return {
      fissionId: this.$route.query.id,
      unionId: this.$route.query.unionId,
      isEnd: false,
      timer: 0,
      // 礼物信息
      giftInfo: {
        status: false,
        receive_status: false,
        gift_type: 0,
        gift_url: ''
      },
      // task信息
      task:[],
      differ_count:0,
      end_time:0,
      invite_count:0,
      friendList: [],
      countDown: {
        day: '-',
        hour: '-',
        min: '-',
        sec: '-',
      },
      //  微信用户数据
      wxUserData: {
        unionId:'o_Ak06mc4BB1ULfwRlimFq_tJ-cM'
      }
    }
  },
  created() {
    // this.getOpenUserInfo()
    this.getDataList();
  },
  methods: {
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        id: that.fissionId
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = '/auth/workFission?id=' + that.fissionId + '&target=' + encodeURIComponent(that.url);
          that.$redirectAuth(redirectUrl);
        }
        this.wxUserData = res.data;
        this.getDataList();
      });
    },
    async getDataList() {
      //获取任务信息
      await taskDataApi({
        unionId: this.unionId,
        fissionId: this.fissionId
      }).then(res => {
        this.differ_count = res.data.differ_count;
        this.end_time = res.data.end_time;
        this.invite_count = res.data.invite_count;
        this.task = res.data.task;

        for(var i=0;i<this.task.length;i++){
          if(this.task[i].status===1){
            this.giftInfo.status=true
            this.giftInfo.gift_type=this.task[i].gift_type
            this.giftInfo.gift_url=this.task[i].gift_url
          }
          if(this.task[i].receive_status===1){
            this.giftInfo.receive_status=true
          }
        }

      })
      //获取邀请的好友列表
      await inviteFriendsApi({
        unionId: this.unionId,
        fissionId: this.fissionId
      }).then(res => {
        this.friendList = res.data;
      })

      this.setCountDown();
    },
    getGift() {
      if(!this.giftInfo.status){
        this.$confirm({
            title: '暂未获得奖励',
            content: '您暂未获得奖励哦，快快分享海报，邀请好友帮忙助力吧',
            okText: '确定',
            cancelText: '取消',
        });
        // this.$message.warn('暂未获得奖励')
        return;
      }
      if(this.giftInfo.receive_status) {
        this.$router.push('/workFission/award?id='+this.fissionId+'&unionId='+this.unionId);
        // this.$message.warn('您的奖励已领取')
        // return;
      }
      if (this.giftInfo.gift_type === 1) {
        window.open(this.giftInfo.gift_url);
      }
      if (this.giftInfo.gift_type === 0) {
        this.$router.push('/workFission/award?id='+this.fissionId+'&unionId='+this.unionId);
      }
    },

    setCountDown() {
      clearInterval(this.timer);

      //先检测一次
      let tempNow = Math.floor(new Date().getTime() / 1000);
      let tempGap = this.end_time - tempNow;

      console.log('剩余时间', tempGap)

      if (tempGap <= 0) {
        this.isEnd = true;
        return;
      }
      this.setTime(tempGap);

      this.timer = setInterval(() => {
        let now = Math.floor(new Date().getTime() / 1000);
        let gap = this.end_time - now;
        if (gap <= 0) {
          this.isEnd = true;
          clearInterval(this.Timer);
          return;
        }
        this.setTime(gap);
      }, 1000);
    },
    setTime(time) {
      this.countDown.day = Math.floor(time / 60 / 60 / 24);
      this.countDown.hour = Math.floor(time / 60 / 60 % 24);
      this.countDown.min = Math.floor(time / 60 % 60);
      this.countDown.sec = Math.floor(time % 60);
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100vw;
  min-height: 100vh;
  background-color: #F3594A;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  .top{
    height: 131px;
    color: white;
    .top-content{
      height: 81px;
      background: #EF453A;
      margin-bottom: -62px;
      .continue{
        font-size: 16px;
        p{
          text-align: center;
          margin: 0;
          font{
            color: #F3BF38;
            margin: 0 5px;
          }
        }
        p:nth-child(1){
          padding-top: 30px;
        }
        p:nth-child(2){
          padding-top: 5px;
        }
        p:nth-child(3){
          display: flex;
          justify-content: center;
          margin-top: 10px;
          icon{
            width: 16px;
            height: 16px;
            background: url("../../static/images/workFission/speed-icon-down.png");
            background-size: cover;
          }
        }
      }
      .end{
        height: 81px;
        p{
          display: flex;
          justify-content: center;
          text-align: center;
          padding-top: 38px;
          icon{
            width: 20px;
            height: 20px;
            margin-right: 5px;
            background: url("../../static/images/workFission/speed-icon-end.png");
            background-size: cover;
          }
        }
      }
    }
    .top-icon{
      height: 111px;
      background: #EF453A;
      border-radius: 60%;
    }
  }
  .djs{
    text-align: center;
    font-size: 14px;
    color: white;
    margin-top: 13px;
    span{
      padding: 1px 5px;
      background: #DC4839;
      border-radius: 3px;
      margin: 0 2px;
    }
  }
  .content{
    padding: 0 24px;
    margin-top: 34px;
    &>p:nth-child(1){
      font-size: 16px;
      color: white;
      padding-left: 5px;
      margin-bottom: 8px;
      font{
        color: #F3BF38;
      }
    }
    .active{
      opacity: .6;
    }
    .content-lie{
      padding: 14px;
      border-radius: 19px;
      background: white;
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      img{
        width: 57px;
        height: 57px;
        border-radius: 12px;
        margin-right: 10px;
      }
      p{
        margin:4px 0 0 0;
      }
      p:nth-child(1){
        font-size: 16px;
        font{
          color: red;
        }
      }
      p:nth-child(2) {
        font-size: 14px;
        color: #999;
        font{
          color: #F3BF38;
        }
      }
    }
  }
  .friendlist{
    margin: 17px 24px;
    padding: 0 24px 24px 24px;
    min-height: 300px;
    background: linear-gradient(180deg,#ec5120, #f5654f 100%);
    border-radius: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: calc(~"46px + 10%");
    .title{
      width: 111px;
      height: 34px;
      font-size: 14px;
      background: #fecc63;
      border-radius: 0px 0px 8px 8px;
      text-align: CENTER;
      color: #ffffff;
      line-height: 34px
    }
    .fl-content{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-self: flex-start;
      border-bottom: 1px solid #F5774C;
      padding: 15px 0;
      img{
        width: 45px;
        height: 45px;
        border-radius: 45px;
        border:2px solid #eeeeee;
      }
      div{
        margin-left: 10px;
        color: white;
        p{
          margin: 0;
        }
        p:nth-child(1){
          font-size: 14px;
        }
        p:nth-child(2){
          font-size: 12px;
          margin-top: 4px;
          font-weight: 400;
        }
      }
    }
    & .fl-content:last-child{
      border-bottom: none;
    }
  }
  .bottom{
    width: 100%;
    background: #FEDFD9;
    position: fixed;
    bottom: 0;
    div{
      width: 92%;
      height: 46px;
      line-height: 46px;
      background: linear-gradient(180deg,#e64e44, #ff9c7d 100%);
      border-radius: 48px;
      font-size: 16px;
      color: white;
      text-align: center;
      margin: 4%;
    }
  }
}
</style>